import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: () => import('../views/Home.vue')
  },

  {
    path: '/preguntas-frecuentes',
    name: 'Preguntas Frecuentes -',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PreguntasFrecuentes.vue')
  },
  {
    path:'/aldeas-inteligentes',
    name:'Aldeas Inteligentes -',
    component: () => import('../views/AldeasInteligentes.vue')
  },
  {
    path:'/aldeas-inteligentes/mapa-interactivo',
    name:'Aldeas Inteligentes - Mapa Interactivo',
    component: () => import('../views/AldeasInteligentesMapaInteractivo.vue')
  },
  {
    path:'/conectividad-sitios-publicosInter',
    name:'Programa de Conectividad en Sitios Públicos',
    component: () => import('../views/conectividadPublicosInter.vue')
  },
  {
    path:'/conectividad-sitios-publicos',
    name:'Programa de Conectividad en Sitios Públicos 2020-2021',
    component: () => import('../views/conectividadPublicos.vue')
  },
  {
    path:'/conectividad-sitios-publicos-2022',
    name:'Programa de Conectividad en Sitios Públicos 2022 -',
    component: () => import('../views/conectividadPublicos2022.vue')
  },
  {
    path:'/conectividad-sitios-publicos-2023',
    name:'Programa de Conectividad en Sitios Públicos 2023 -',
    component: () => import('../views/conectividadPublicos2023.vue')
  },
  {
    path:'/conectividad-sitios-publicos-2024',
    name:'Programa de Conectividad en Sitios Públicos 2024 -',
    component: () => import('../views/conectividadPublicos2024.vue')
  },
  {
    path:'/repositorio-unico',
    name:'Repositorio unico -',
    component:() => import('../views/RepositorioUnico.vue')
  },
  {
    path:'/cobertura-socialMenu',
    name:'Cobetura Social -',
    component:() => import('../views/CoberturaSocialMenu.vue')
  },
  {
    path:'/cobertura-social',
    name:'Cobetura Social -',
    component:() => import('../views/CoberturaSocial.vue')
  },
  {
    path:'/cobertura-social-2022',
    name:'Cobetura Social -',
    component:() => import('../views/CoberturaSocial2022.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next )=>{
  let aux = from;
  aux.fullPath;
  document.title =  `${to.name} ${ process.env.VUE_APP_TITLE }` ;
  next();
});
export default router
